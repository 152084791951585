export interface QuizzProgressionProps {
  steps: (boolean | null)[];
  currentStep: number;
}

export default function QuizzProgression(props: QuizzProgressionProps) {
  return (
    <div className="flex items-center flex-col">
      <p className="text-sm font-medium">
        Question {props.currentStep} / {props.steps.length}
      </p>
      <ol role="list" className="mt-3 flex items-center space-x-5">
        {props.steps.map((step, i) => (
          <li key={i}>
            <div
              className="relative flex items-center justify-center"
              aria-current="step"
            >
              {i == props.currentStep && (
                <span className="absolute w-5 h-5 p-px flex">
                  <span className="w-full h-full rounded-full bg-gray-300" />
                </span>
              )}

              {step === true && (
                <span className="relative block w-2.5 h-2.5 bg-green-500 rounded-full" />
              )}

              {step === false && (
                <span className="relative block w-2.5 h-2.5 bg-red-500 rounded-full" />
              )}

              {step === null && (
                <span className="relative block w-2.5 h-2.5 bg-gray-200 rounded-full" />
              )}
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}
