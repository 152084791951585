import { Link } from "react-router-dom";

const quizzes = [
  {
    name: "1..26 => A..Z",
    description:
      "Testez votre capacité à passer des nombres aux lettres dans l'alphabet !",
    href: "/quizz/numbers-to-letters",
  },
  {
    name: "A..Z => 1..26",
    description:
      "Testez votre capacité à retrouver la position des lettres de l'alphabet !",
    href: "/quizz/letters-to-numbers",
  },
];

export default function HomePage() {
  return (
    <div className="pb-20 min-h-full bg-gray-100">
      <div className="max-w-7xl mx-auto pt-24 pb-14 px-8 text-center">
        <h1 className="mt-1 text-6xl font-extrabold text-gray-900">Quiz</h1>
        <p className="max-w-xl mt-5 mx-auto text-xl text-gray-500">
          Une liste de quiz pour apprendre en s'amusant !
        </p>
      </div>

      <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
        {quizzes.map((quizz, i) => (
          <div
            key={i}
            className="flex flex-col rounded-lg shadow-lg overflow-hidden"
          >
            <div className="px-10 pt-8 pb-4 bg-white text-center">
              <h2 className="text-5xl font-extrabold">{quizz.name}</h2>
              <p className="mt-4 text-lg text-gray-500">{quizz.description}</p>
            </div>
            <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
              <div className="rounded-md shadow">
                <Link
                  to={quizz.href}
                  className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                  aria-describedby="tier-standard"
                >
                  Commencer
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
