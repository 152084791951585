import QuizzWithTextInput from "../../components/QuizzWithTextInput";
import { randomIntFromInterval } from "../../utils";

export default function NumbersToLettersPage() {
  const getQuestion = (): { question: string; answer: string } => {
    const number = randomIntFromInterval(1, 26);
    const letter = String.fromCharCode(64 + number);

    return {
      question: letter,
      answer: number.toString(),
    };
  };

  return (
    <QuizzWithTextInput
      title="1..26 => A..Z"
      description="Retrouvez la position des lettres de l'alphabet !"
      numberOfSteps={20}
      getQuestion={getQuestion}
    />
  );
}
