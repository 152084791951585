import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import { NotFoundPage } from "./pages/NotFoundPage";
import LettersToNumbersPage from "./pages/quizz/LettersToNumbersPage";
import NumbersToLettersPage from "./pages/quizz/NumbersToLettersPage";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="quizz/numbers-to-letters"
        element={<NumbersToLettersPage />}
      />
      <Route
        path="quizz/letters-to-numbers"
        element={<LettersToNumbersPage />}
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
