import { useState } from "react";
import { useForm } from "react-hook-form";
import QuizzProgression from "./QuizzProgression";
import QuizzResults from "./QuizzResults";

interface FormData {
  value: string;
}

export interface InputQuestion {
  question: string;
  answer: string;
}

export interface QuizzResponse {
  question: InputQuestion;
  answer: string;
  isCorrect: boolean;
}

export interface QuizzWithTextInputProps {
  title: string;
  description: string;
  numberOfSteps: number;
  getQuestion: () => InputQuestion;
}

export default function QuizzWithTextInput(props: QuizzWithTextInputProps) {
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [responses, setResponses] = useState<QuizzResponse[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<InputQuestion>(
    props.getQuestion()
  );
  const [currentAnswerIsCorrect, setCurrentAnswerIsCorrect] = useState<boolean>(true);

  const { register, handleSubmit, setValue } = useForm<FormData>();
  const onSubmit = (data: FormData) => {
    const isCorrect = data.value.toUpperCase() === currentQuestion.answer.toUpperCase();
    const input = document.getElementById("quizz-input");
    if (isCorrect) {
      setResponses([
        ...responses,
        {
          question: currentQuestion,
          answer: data.value,
          isCorrect: currentAnswerIsCorrect,
        },
      ]);
      setValue("value", "");
      setCurrentQuestion(props.getQuestion());
      setCurrentStep(currentStep + 1);
      setCurrentAnswerIsCorrect(true);
      input?.setAttribute("placeholder", "");
    } else {
      setCurrentAnswerIsCorrect(false);
      input?.classList.remove("focus:border-indigo-500", "border-gray-200");
      input?.classList.add("shake", "border-rose-500");
      input?.setAttribute("placeholder", data.value);
      setValue("value", "");
      input?.addEventListener("animationend", () => {
        input?.classList.remove("shake", "border-rose-500");
        input?.classList.add("focus:border-indigo-500", "border-gray-200");
        input?.focus();
      });
    }
  };

  const start = () => {
    setCurrentQuestion(props.getQuestion());
    setCurrentStep(currentStep + 1);
  };

  const steps = [...new Array(props.numberOfSteps)].map(
    (_, i) => responses[i]?.isCorrect ?? null
  );

  return (
    <div className="flex flex-col py-10 min-h-full bg-gray-100">
      <div className="max-w-7xl mx-auto px-8 text-center">
        <h1 className="mt-1 text-4xl font-extrabold text-gray-900">
          {props.title}
        </h1>
        <p className="max-w-xl mt-1 mx-auto text-xl text-gray-500">
          {props.description}
        </p>
      </div>
      {currentStep >= props.numberOfSteps && (
        <div className="max-w-7xl mx-auto mt-5 px-8 text-center">
          <QuizzResults responses={responses} />
        </div>
      )}
      <div className="flex-1"></div>
      <div className="max-w-7xl mx-auto px-8 text-center">
        {currentStep == -1 && (
          <>
            <button
              className="mt-3 px-6 py-3 rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              onClick={start}
            >
              Commencer
            </button>
          </>
        )}
        {currentStep >= 0 && currentStep < props.numberOfSteps && (
          <>
            <h1 className="mt-1 text-6xl font-extrabold text-gray-900">
              {currentQuestion.question}
            </h1>

            <form
              className="mt-10 flex flex-col w-60"
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                type="text"
                autoComplete="off"
                className="border-2 outline-none p-3 shadow-sm text-6xl text-center focus:border-indigo-500 border-gray-200 rounded-md"
                maxLength={2}
                autoFocus
                id="quizz-input"
                {...register("value")}
              />

              <button className="mt-3 px-6 py-3 rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                Valider
              </button>
            </form>
          </>
        )}
      </div>

      <div className="flex-1"></div>
      {currentStep >= 0 && currentStep < props.numberOfSteps && (
        <div className="mt-10">
          <QuizzProgression steps={steps} currentStep={currentStep} />
        </div>
      )}
    </div>
  );
}
