import QuizzWithTextInput from "../../components/QuizzWithTextInput";
import { randomIntFromInterval } from "../../utils";

export default function LettersToNumbersPage() {
  const getQuestion = (): { question: string; answer: string } => {
    const number = randomIntFromInterval(1, 26);
    const letter = String.fromCharCode(64 + number);

    return {
      question: number.toString(),
      answer: letter,
    };
  };

  return (
    <QuizzWithTextInput
      title="A..Z => 1..26"
      description="Retrouvez la lettre avec sa position dans l'alphabet !"
      numberOfSteps={20}
      getQuestion={getQuestion}
    />
  );
}
